<div *ngIf="myHunt.clues[idclues].type == 'fourchette' " class="chasse-fourchette">
    <p *ngIf="HuntService.utilisateur.position == idclues" class="txt-secondary" [innerHTML]="myHunt.clues[idclues].content"></p>
    <input *ngIf="HuntService.utilisateur.position == idclues" type="number" inputmode="decimal" class="reponse-txt" placeholder="Votre réponse" [(ngModel)]="HuntService.utilisateur.reponseQuestion">
    <p class="txt-secondary"  *ngIf="HuntService.utilisateur.position > idclues" [innerHTML]="myHunt.clues[idclues].unlock_content"></p>
    <div *ngIf="HuntService.utilisateur.position == idclues">
        <p class="error-message" *ngIf="errorMessage?true:false">{{errorMessage}}</p>
        <button class="button-totemus" [disabled]="HuntService.utilisateur.reponseQuestion ? false : true" (click)="checkFourchette(myHunt.clues[idclues].reponse_min,myHunt.clues[idclues].reponse_max,HuntService.utilisateur.reponseQuestion)" >je valide ma réponse</button>
        <p class="indice" *ngIf="myHunt.clues[idclues].help[0]" [innerHTML]="myHunt.clues[idclues].help[0].label" (click)="showIndice()"></p>
    </div>
    <div class="symbol-box" *ngIf="myHunt.clues[idclues].has_symbol == true && HuntService.utilisateur.position > idclues" [style.backgroundColor]="myHunt.clues[idclues].symbol.color">
        <img [src]="myHunt.clues[idclues].symbol.image.s160" alt="symbole débloqué">
    </div>
    <button *ngIf="HuntService.utilisateur.position > idclues" class="button-totemus" (click)="showNextStep()">Prochaine étape</button>
</div>
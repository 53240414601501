import { Component, OnInit, Input } from '@angular/core';
import { HuntService } from '../services/hunt.service';

@Component({
  selector: 'app-sac',
  templateUrl: './sac.component.html',
  styleUrls: ['./sac.component.scss']
})
export class SacComponent implements OnInit {
  @Input() clue: any;
  @Input() title: any;
  @Input() image: any;

  constructor(public HuntService : HuntService) { }

  ngOnInit(): void {
  }
  showBagFunction(){
    this.HuntService.utilisateur.showBag = true;
    document.body.classList.add('modal');
  }
  hideBag(){
    if(this.HuntService.utilisateur.position == 14 && this.clue == 14){
      this.HuntService.utilisateur.showBag = false;
    }else{
      this.HuntService.utilisateur.showBag = false;
      document.body.classList.remove('modal');
    }
    
  }


}

import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { HuntService } from '../services/hunt.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  @Input() myHunt: any;
  @Input() idclues: any;
  @Input() showRules: any;
  errorMessage: any;
  @Output() rulesUpdate: EventEmitter<String> = new EventEmitter<String>();
  updateParent(name){
    this.rulesUpdate.emit(name);
  }
  @Output() nextStepUpdate: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  nextStep(name: Boolean){
    this.nextStepUpdate.emit(name);
  }
  constructor(public HuntService : HuntService) { }

  ngOnInit(): void {
    console.log(this.myHunt);
  }
  showrule(){
    this.updateParent("solved");
  }
  startPoint(){
    this.nextStep(true);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {ApiHunt} from '../apiHunt';
import { HuntService } from './hunt.service';


@Injectable({
  providedIn: 'root'
})
export class ApiHuntService {
  private readonly HUNT_URL = 'https://mediacite.noodev.be/wp-json/myhunts/v1/hunt/';
  private readonly HUNT_POST_URL = "https://mediacite.noodev.be/wp-json/muhunts/v1/participations/start";
  
  constructor(private http: HttpClient, HuntService: HuntService) { 

  }

  load(){
    return this.http.get(this.HUNT_URL);
 }

 public getHunt():Observable<any>{
  return this.http.get<any>(this.HUNT_URL)
 }

 postUser(user){
   this.http.post<any>(this.HUNT_POST_URL,user).toPromise().then(data =>{
     console.log(data);
   })
 }
}

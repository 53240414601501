<div  class="info gsap_box" *ngIf="myHunt.clues[idclues].type === 'info' && showRules == true ">
    <h4 class="title_rules">Règles</h4>
    <p class="txt-secondary" [innerHTML]="myHunt.clues[idclues].rules"></p>
    <button  class="button-totemus" (click)="startPoint()">{{myHunt.clues[idclues].button_rules}} </button>
</div>
<div  class="info gsap_box" *ngIf="myHunt.clues[idclues].type === 'info' && showRules == false">
    <p class="txt-secondary" [innerHTML]="myHunt.clues[idclues].unlock_content"></p>
    <h4 [innerHTML]="myHunt.clues[idclues].title"></h4>
    <div class="start-img" [style.backgroundImage]="'url('+ HuntService.chasse.clues[idclues].img_start +')'">
        <div class="start-txt">
            <p [innerHTML]="myHunt.clues[idclues].subtitle_start"></p>
        </div>
    </div>
    <button  class="button-totemus" (click)="showrule()">{{myHunt.clues[idclues].button_start}} </button>
</div>
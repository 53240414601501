import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { HuntService } from '../services/hunt.service';

@Component({
  selector: 'app-fourchette',
  templateUrl: './fourchette.component.html',
  styleUrls: ['./fourchette.component.scss']
})
export class FourchetteComponent implements OnInit {
  @Input() myHunt: any;
  @Input() idclues: any;
  errorMessage: any;
  @Output() fourchetteUpdate: EventEmitter<String> = new EventEmitter<String>();
  updateParent(name){
    this.fourchetteUpdate.emit(name);
  }
  @Output() indiceUpdate: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  updateIndice(name: Boolean){
    this.indiceUpdate.emit(name);
  }
  @Output() nextStepUpdate: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  nextStep(name: Boolean){
    this.nextStepUpdate.emit(name);
  }
  constructor(public HuntService : HuntService) { }

  ngOnInit(): void {
  }
  showIndice(){
    this.updateIndice(true);
  }
  showNextStep(){
    this.nextStep(true);
  }
  checkFourchette(min,max,reponse){
    if(reponse>=min && reponse<=max){
      this.updateParent('solved');
    }
    else{
      this.errorMessage = "Pas la bonne réponse 🙁"
    }
  }

}

import { Component, OnInit, OnChanges} from '@angular/core';
import { HuntService } from '../services/hunt.service';
import {ApiHuntService} from '../services/api-hunt.service';
import { Observable } from 'rxjs';
import {ApiHunt} from '../apiHunt';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { animate, stagger, style, transition, trigger,query } from '@angular/animations';
// import {gsap, TimelineMax, TimelineLite, Power1} from 'gsap';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  myHunt: any; 
  user= {
    firstname: null,
    lastname: null,
    email: null
  };
  errors: any;
  maReponse: any;
  form: FormGroup;
  loader: boolean=true;
  showQuestionSub: boolean=false;

  
  
  constructor(public router: Router,private http: HttpClient,public HuntService: HuntService,public ApiHuntService: ApiHuntService,public fb: FormBuilder) { 
    this.form = this.fb.group({
      email: [''],
      lastname: [''],
      firstname: [''],
      nbParticipant: ['1']
    })
  }
  ngOnInit(): void {
    //A DESACTIVER POUR MISE EN LIGNE
    localStorage.clear();
    //
    document.body.classList.remove('modal');
    this.HuntService.utilisateur.condition = false;
    if(localStorage.getItem("participation_id")){
      // this.router.navigate(['/hunt']);
    }
    this.ApiHuntService.getHunt().subscribe((result) => {
      this.myHunt = result;
      this.loader = false;
    })
  }
  
  submitForm() {
      // var formData: any = new FormData();
      // formData.append("lastname", this.form.get('lastname').value);
      // formData.append("firstname", this.form.get('firstname').value);
      // formData.append("email", this.form.get('email').value);
      // formData.append("number_of_participants", this.form.get('nbParticipant').value);
      // console.log(formData);
      
      
      // this.http.post("https://mediacite.noodev.be/wp-json/myhunts/v1/participations/start", formData).subscribe({
      //   next: data => {
      //     this.maReponse = data;
      //     localStorage.setItem("participation_id",this.maReponse.participation_id);
      //     localStorage.setItem("utilisateur_position","0");
      //     let date = new Date();
      //     let dateForLocalstorage = date.getTime().toString();
      //     localStorage.setItem("lastTime",dateForLocalstorage);
    
      //     console.log(localStorage.getItem("participation_id"));
      //     console.log(localStorage.getItem("utilisateur_position"));
      //     this.loader = false;
          
          

      //   },
      //   error: error => {
      //     this.errors = "Adresse e-mail invalide";
      //     this.loader = false;
      //     console.log(this.errors);
      //   }
      // } 
      // )
      this.router.navigate(['/hunt']);
      
        
        
    }
  

}

import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { HuntService } from '../services/hunt.service';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {
  @Input() myHunt: any;
  @Input() idclues: any;
  errorMessage: any;
  @Output() dateUpdate: EventEmitter<String> = new EventEmitter<String>();
  updateParent(name){
    this.dateUpdate.emit(name);
  }
  @Output() indiceUpdate: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  updateIndice(name: Boolean){
    this.indiceUpdate.emit(name);
  }
  @Output() nextStepUpdate: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  nextStep(name: Boolean){
    this.nextStepUpdate.emit(name);
  }
  constructor(public HuntService : HuntService) { }

  ngOnInit(): void {
  }
  checkDate(Reponse,dateFormat){
    
    var dateAlt = Reponse.split('/');

    if(dateFormat == "YYYY"){
      
      if(this.HuntService.utilisateur.reponseQuestionDate[2].toString() === dateAlt[2]){
        this.HuntService.utilisateur.reponseQuestionDate = [];
        this.updateParent("solved");
        
      }else{
        this.errorMessage = "Pas la bonne date 🙁";
      }
    }
    if(dateFormat == "DD/MM/YYYY"){
      let day = null;
      let month = null;
      let year = this.HuntService.utilisateur.reponseQuestionDate[2].toString();

      if(this.HuntService.utilisateur.reponseQuestionDate[0]<10){
        day = "0"+this.HuntService.utilisateur.reponseQuestionDate[0].toString();
      }
      if(this.HuntService.utilisateur.reponseQuestionDate[1]<10){
        month = "0"+this.HuntService.utilisateur.reponseQuestionDate[1].toString();
      }
      
      if(day === dateAlt[0] && month === dateAlt[1] && year === dateAlt[2]){
        this.HuntService.utilisateur.reponseQuestionDate = [];
        this.updateParent("solved");
      }else{
        this.errorMessage = "Pas la bonne date 🙁";
        console.error('pas bon');
      }
    }
  }
  showIndice(){
    this.updateIndice(true);
  }
  showNextStep(){
    this.nextStep(true);
  }

}

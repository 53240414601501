<p class="txt-secondary" [innerHTML]="title"></p>
<div class="puzzleBox">
    <ul class="thePuzzle" id="puzzle">
        <li tabindex="3" class="puzzlePiece puz3" ><span [ngClass]="{puzzleNum : showNumber == true}">4</span></li>
        <li tabindex="4" class="puzzlePiece puz4"><span [ngClass]="{puzzleNum : showNumber == true}">5</span></li>
        <li tabindex="1" class="puzzlePiece puz1" ><span [ngClass]="{puzzleNum : showNumber == true}">2</span></li>
        <li tabindex="2" class="puzzlePiece puz2"><span [ngClass]="{puzzleNum : showNumber == true}">3</span></li>
        <li tabindex="8" class="puzzlePiece puz8"><span [ngClass]="{puzzleNum : showNumber == true}">9</span></li>
        <li tabindex="7" class="puzzlePiece puz7"><span [ngClass]="{puzzleNum : showNumber == true}">8</span></li>
        <li tabindex="5" class="puzzlePiece puz5"><span [ngClass]="{puzzleNum : showNumber == true}">6</span></li>
        <li tabindex="6" class="puzzlePiece puz6"><span [ngClass]="{puzzleNum : showNumber == true}">7</span></li>
        <li tabindex="0" #puzzle1 class="puzzlePiece puz0"><span [ngClass]="{puzzleNum : showNumber == true}">1</span></li>
    </ul>
</div>
<div class="puzzleBox">
    <img src="../../assets/img/zebra.jpeg" alt="medicité" width="180px">
</div>
<!-- <button class="button-totemus" (click)="dontPuzzle()">passer le puzzle</button>  -->
<p class="indice" (click)="showIndiceFunction()">Besoin d'un indice?</p>

<div *ngIf="HuntService.utilisateur.showBag" class="bag-view" >
    <div class="modal-back" (click)="hideBag()"></div>
    <div class="bag-content" >
        <i  class="fas fa-times" (click)="hideBag()"></i>
        <h2>
            Mon sac à dos
        </h2>
            <!-- <ul class="list-bag-img">
                <div class="row1 top">
                    <li [style.backgroundColor]="HuntService.utilisateur.sacColor[0]">
                        <img *ngIf="HuntService.utilisateur.sac[0]" src="{{HuntService.utilisateur.sac[0]}}" alt="">
                    </li>
                </div>
                <div class="row2 rowTop">
                    <li [style.backgroundColor]="HuntService.utilisateur.sacColor[7]">
                        <img *ngIf="HuntService.utilisateur.sac[7]" src="{{HuntService.utilisateur.sac[7]}}" alt="">
                    </li>
                    <li [style.backgroundColor]="HuntService.utilisateur.sacColor[1]">
                        <img *ngIf="HuntService.utilisateur.sac[1]" src="{{HuntService.utilisateur.sac[1]}}" alt="">
                    </li>
                </div>
                <div class="row3">
                    <li [style.backgroundColor]="HuntService.utilisateur.sacColor[6]">
                        <img *ngIf="HuntService.utilisateur.sac[6]" src="{{HuntService.utilisateur.sac[6]}}" alt="">
                    </li>
                    
                    
                        <img [src]="image" class="logo-central" alt="ecosystème">
                    
                    <li [style.backgroundColor]="HuntService.utilisateur.sacColor[2]">
                        <img *ngIf="HuntService.utilisateur.sac[2]" src="{{HuntService.utilisateur.sac[2]}}" alt="">
                    </li>
                </div>
                <div class="row2 rowBottom">
                    <li [style.backgroundColor]="HuntService.utilisateur.sacColor[5]">
                        <img *ngIf="HuntService.utilisateur.sac[5]" src="{{HuntService.utilisateur.sac[5]}}" alt="">
                    </li>
                    <li [style.backgroundColor]="HuntService.utilisateur.sacColor[3]">
                        <img *ngIf="HuntService.utilisateur.sac[3]" src="{{HuntService.utilisateur.sac[3]}}" alt="">
                    </li>
                </div>
                <div class="row1 bottom">
                    <li [style.backgroundColor]="HuntService.utilisateur.sacColor[4]">
                        <img *ngIf="HuntService.utilisateur.sac[4]" src="{{HuntService.utilisateur.sac[4]}}" alt="">
                    </li>
                </div>
            </ul> -->
            <ul class="bagList">
                <li>
                    <img *ngIf="HuntService.utilisateur.sac[0]" src="{{HuntService.utilisateur.sac[0]}}" alt="">
                </li>
                <li>
                    <img *ngIf="HuntService.utilisateur.sac[1]" src="{{HuntService.utilisateur.sac[1]}}" alt="">
                </li>
                <li>
                    <img *ngIf="HuntService.utilisateur.sac[2]" src="{{HuntService.utilisateur.sac[2]}}" alt="">
                </li>
                <li>
                    <img *ngIf="HuntService.utilisateur.sac[3]" src="{{HuntService.utilisateur.sac[3]}}" alt="">
                </li>
                <li>
                    <img *ngIf="HuntService.utilisateur.sac[4]" src="{{HuntService.utilisateur.sac[4]}}" alt="">
                </li>
                <li>
                    <img *ngIf="HuntService.utilisateur.sac[5]" src="{{HuntService.utilisateur.sac[5]}}" alt="">
                </li>
            </ul>
    </div>
</div>

<app-loader *ngIf="loader==true"></app-loader>
<section *ngIf="myHunt">
    <div class="hunter_header"[style.backgroundImage]="'url('+ HuntService.chasse.imgHead +')'"> 
    <!-- <div class="hunter_header"> -->
        <img src="../../assets/logo-ludifica.png" alt="logo mediacite">
    </div>
    <div class="container_home animation_home">
        
        <h1 class="gsap_box">{{HuntService.chasse.titre }}</h1>
        <p class="duree gsap_box">Durée: {{myHunt.duration}}</p>
        
        <p class="totem_p gsap_box" [innerHTML]="HuntService.chasse.texte_intro "></p>
        
        <h2 class="gsap_box"> <img src="../../assets/img/icon-gift.svg" alt="">  À remporter</h2>
        <p class="totem_p gsap_box" [innerHTML]="HuntService.chasse.texte_reward"></p>
        <div class="hunter_foot gsap_box gift_img"[style.backgroundImage]="'url('+ HuntService.chasse.imgFoot  +')'"></div>
        <!-- <h2>Je participe </h2>
        <form  #f="ngForm" [formGroup]="form" >
            <input class="input-style" type="text" required name="firstname" id="" placeholder="Prénom" formControlName="firstname">
            <input class="input-style" type="text" required name="nom" id="nom" placeholder="Nom" formControlName="lastname">
            <input class="input-style" type="email" required name="email" id="email" placeholder="Adresse email" formControlName="email">
            <div class="checkbox-box">
                <p class="checkbox"  (click)="HuntService.utilisateur.condition = !HuntService.utilisateur.condition" [ngClass]="{check_active : HuntService.utilisateur.condition == true}"></p>
                <label for="condition">J'ai lu et j'accepte les <a target="_blank" [href]="myHunt.link_cgu" class="red-text">conditions générales d'utilisation</a> .</label>
            </div>
            <p *ngIf="errors">{{errors}}</p>

            <button class="submit-participation" [disabled]="form.valid && HuntService.utilisateur.condition == true ? false : true" (click)="submitForm()" >Je joue</button>
        </form> -->
        <button class="submit-participation"  (click)="submitForm()" >Je joue</button>

    </div>
     
</section>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HuntService } from '../services/hunt.service';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class AudioComponent implements OnInit {
  @Input() myHunt: any;
  @Input() idclues: any;
  errorMessage: any;
  @Output() audioUpdate: EventEmitter<String> = new EventEmitter<String>();
  updateParent(name){
    this.audioUpdate.emit(name);
  }
  @Output() indiceUpdate: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  updateIndice(name: Boolean){
    this.indiceUpdate.emit(name);
  }
  @Output() nextStepUpdate: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  nextStep(name: Boolean){
    this.nextStepUpdate.emit(name);
  }
  constructor(public HuntService : HuntService) { }

  ngOnInit(): void {
  }
  checkSound(reponse,tab){
    var checkReponse = reponse.toLowerCase();
    var goodResponse;
    tab.forEach(element => {
      if(checkReponse == element.value){
        goodResponse = true;
      }
    });

    if(goodResponse == true){
      console.log("ok sound good");
      this.updateParent("solved");
    }else{
      this.errorMessage = "Pas la bonne réponse 🙁";
    }
  }
  showIndice(){
    this.updateIndice(true);
  }
  showNextStep(){
    this.nextStep(true);
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HuntComponent } from './hunt/hunt.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { HttpClientModule} from '@angular/common/http';
import { HuntService } from './services/hunt.service';
import { LoaderComponent } from './loader/loader.component';
import { PuzzleComponent } from './puzzle/puzzle.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SacComponent } from './sac/sac.component';
import { AudioComponent } from './audio/audio.component';
import { QcmComponent } from './qcm/qcm.component';
import { DateComponent } from './date/date.component';
import { FourchetteComponent } from './fourchette/fourchette.component';
import { DragdropComponent } from './dragdrop/dragdrop.component';
import { InfoComponent } from './info/info.component';







@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HuntComponent,
    LoaderComponent,
    PuzzleComponent,
    SacComponent,
    AudioComponent,
    QcmComponent,
    DateComponent,
    FourchetteComponent,
    DragdropComponent,
    InfoComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ZXingScannerModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DragDropModule,
    ScrollingModule 
    
    
    
    
    
  ],
  providers: [HuntService, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, Input,Output, OnInit, EventEmitter , ViewChild} from '@angular/core';
import { Sortable, Swap } from 'sortablejs/modular/sortable.core.esm';
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-puzzle',
  templateUrl: './puzzle.component.html',
  styleUrls: ['./puzzle.component.scss']
})
export class PuzzleComponent implements OnInit {
  @Input() background: any;
  @Input() title: any;
  showNumber: boolean = false;
  @Output() puzzleUpdate: EventEmitter<String> = new EventEmitter<String>();
  @ViewChild('puzzle1') p1; 
  updateParent(name){
    this.puzzleUpdate.emit(name);
  }
  puzzleTab = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9
  ]
  
  constructor() { }

  ngOnInit(): void {
    // this.buildPuzzle(this.background);
    // console.log(this.background);
    Sortable.mount(new Swap());
    let stringus= "l'eau";
    console.log(stringus.toLowerCase());
    if(stringus)
    var el = document.getElementById("puzzle");
    let that = this;
    var sortable = Sortable.create(el,{
      swap: true,
      onEnd: function(e){
        if($('.puzzlePiece')[0].tabIndex == 0 && $('.puzzlePiece')[1].tabIndex == 1 && $('.puzzlePiece')[2].tabIndex == 2 && $('.puzzlePiece')[3].tabIndex == 3 && $('.puzzlePiece')[4].tabIndex == 4 && $('.puzzlePiece')[5].tabIndex == 5 && $('.puzzlePiece')[6].tabIndex == 6 && $('.puzzlePiece')[7].tabIndex == 7){
               console.log("yes");
               console.log(that.updateParent("solved"));
               console.log("test");
             }
      }
    });
    
    
  }
  showIndiceFunction(){
    this.showNumber = true;
  }
  dontPuzzle(){
    this.updateParent('solved');
  }


 }

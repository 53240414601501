import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { HuntService } from '../services/hunt.service';

@Component({
  selector: 'app-dragdrop',
  templateUrl: './dragdrop.component.html',
  styleUrls: ['./dragdrop.component.scss']
})
export class DragdropComponent implements OnInit {
  successAudio:any;
  errorAudio:any;
  dechetsTab=[];  
  
  @Input() myHunt: any;
  @Input() idclues: any;
  errorMessage: any;
  @Output() dragUpdate: EventEmitter<String> = new EventEmitter<String>();
  updateParent(name){
    this.dragUpdate.emit(name);
  }
  // @Output() indiceUpdate: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  // updateIndice(name: Boolean){
  //   this.indiceUpdate.emit(name);
  // }
  @Output() nextStepUpdate: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  nextStep(name: Boolean){
    this.nextStepUpdate.emit(name);
  }

  constructor(public HuntService : HuntService) { }

  ngOnInit(): void {
     //AUDIO
     this.successAudio = new Audio();
     this.successAudio.src = "../../assets/audio/success.mp3";
     this.errorAudio = new Audio();
     this.errorAudio.src = "../../assets/audio/error.mp3";
  }
  successSound(){
    this.successAudio.load();
    this.successAudio.play();
  }
  errorSound(){
    this.errorAudio.load();
    this.errorAudio.play();
  }
  checkEndGame(){
    if(this.dechetsTab[0] === 0 && this.dechetsTab[1] === 0 && this.dechetsTab[2] === 0 && this.dechetsTab[3] === 0){
      this.updateParent('solved');
    }
  }
  public onDragEnded(event,nbDechet,type_poubelle): void {
    let axeY = event.source.getFreeDragPosition().y; // returns { x: 0, y: 0 }
    let axeX = event.source.getFreeDragPosition().x; // returns { x: 0, y: 0 }
    console.log("y : "+axeY);
    console.log("x : "+axeX);
    
    if(axeY>270 && axeX<3){
      if(type_poubelle == "pmc"){
        this.successSound();
        this.dechetsTab[nbDechet]=0;
      }else{
        this.errorSound()
        event.source.reset();
      }
      
    }else if(axeY>270 && axeX>126){
      if(type_poubelle == "autre"){
        this.successSound();
        this.dechetsTab[nbDechet]=0;
      }else{
        this.errorSound()
        event.source.reset();
      }
    }else if(axeY>270){
      if(type_poubelle == "papier"){
        this.successSound();
        this.dechetsTab[nbDechet]=0;
      }else{
        this.errorSound()
        event.source.reset();
      }
      
    }
    console.log(this.dechetsTab.length);
    this.checkEndGame();
  }
  public onDragEnded2(event,nbDechet,type_poubelle): void {
    let axeY = event.source.getFreeDragPosition().y; // returns { x: 0, y: 0 }
    let axeX = event.source.getFreeDragPosition().x; // returns { x: 0, y: 0 }
    console.log("y : "+axeY);
    console.log("x : "+axeX);
    if(axeY>270 && axeX<-128){
      if(type_poubelle == "pmc"){
        this.successSound();
        this.dechetsTab[nbDechet]=0;
      }else{
        this.errorSound()
        event.source.reset();
      }
      
    }else if(axeY>270 && axeX>0){
      if(type_poubelle == "autre"){
        this.successSound();
        this.dechetsTab[nbDechet]=0;
      }else{
        this.errorSound()
        event.source.reset();
      }
    }else if(axeY>270){
      if(type_poubelle == "papier"){
        this.successSound();
        this.dechetsTab[nbDechet]=0;
      }else{
        this.errorSound()
        event.source.reset();
      }
      
    }
    console.log(this.dechetsTab.length);
    this.checkEndGame();
  }
  public onDragEnded3(event,nbDechet,type_poubelle): void {
    let axeY = event.source.getFreeDragPosition().y; // returns { x: 0, y: 0 }
    let axeX = event.source.getFreeDragPosition().x; // returns { x: 0, y: 0 }
    console.log("y : "+axeY);
    console.log("x : "+axeX);
    if(axeY>180 && axeX<3){
      if(type_poubelle == "pmc"){
        this.successSound();
        this.dechetsTab[nbDechet]=0;
      }else{
        this.errorSound()
        event.source.reset();
      }
      
    }else if(axeY>180 && axeX>126){
      if(type_poubelle == "autre"){
        this.successSound();
        this.dechetsTab[nbDechet]=0;
      }else{
        this.errorSound()
        event.source.reset();
      }
    }else if(axeY>180){
      if(type_poubelle == "papier"){
        this.successSound();
        this.dechetsTab[nbDechet]=0;
      }else{
        this.errorSound()
        event.source.reset();
      }
      
    }
    console.log(this.dechetsTab.length);
    this.checkEndGame();
  }
  public onDragEnded4(event,nbDechet,type_poubelle): void {
    let axeY = event.source.getFreeDragPosition().y; // returns { x: 0, y: 0 }
    let axeX = event.source.getFreeDragPosition().x; // returns { x: 0, y: 0 }
    console.log("y : "+axeY);
    console.log("x : "+axeX);
    if(axeY>180 && axeX<-128){
      if(type_poubelle == "pmc"){
        this.successSound();
        this.dechetsTab[nbDechet]=0;
      }else{
        this.errorSound()
        event.source.reset();
      }
      
    }else if(axeY>180 && axeX>0){
      if(type_poubelle == "autre"){
        this.successSound();
        this.dechetsTab[nbDechet]=0;
      }else{
        this.errorSound();
        event.source.reset();
      }
    }else if(axeY>180){
      if(type_poubelle == "papier"){
        this.successSound();
        this.dechetsTab[nbDechet]=0;
      }else{
        this.errorSound()
        event.source.reset();
      }
      
    }
   console.log(this.dechetsTab.length);
   this.checkEndGame();
  }
  showNextStep(){
    this.nextStep(true);
  }

}

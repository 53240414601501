import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { HuntService } from '../services/hunt.service';

@Component({
  selector: 'app-qcm',
  templateUrl: './qcm.component.html',
  styleUrls: ['./qcm.component.scss']
})
export class QcmComponent implements OnInit {
  qcm=[];
  qcmGood=[];
  @Input() myHunt: any;
  @Input() idclues: any;
  errorMessage: any;
  @Output() qcmUpdate: EventEmitter<String> = new EventEmitter<String>();
  updateParent(name){
    this.qcmUpdate.emit(name);
  }
  @Output() indiceUpdate: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  updateIndice(name: Boolean){
    this.indiceUpdate.emit(name);
  }
  @Output() nextStepUpdate: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  nextStep(name: Boolean){
    this.nextStepUpdate.emit(name);
  }
  constructor(public HuntService : HuntService) { }

  ngOnInit(): void {
  }
  showIndice(){
    this.updateIndice(true);
  }
  showNextStep(){
    this.nextStep(true);
  }
  checkQCM(good,answer,positionTab){
    if(good == answer){
      this.qcmGood[positionTab]=true;
      let successAudio = new Audio();
      successAudio.src = "../../assets/audio/success.mp3";
      successAudio.load();
      successAudio.play();
      setTimeout(() => {
        this.updateParent("solved")
      
      }, 1000);
      

    }else{
      this.qcm[positionTab] = true;
      let errorAudio = new Audio();
      errorAudio.src = "../../assets/audio/error.mp3";
      errorAudio.load();
      errorAudio.play();

    }

  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable} from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class HuntService {
  apiUrl = "http://mediacite.noodev.be/wp-json/myhunts/v1/hunt/";


  qrResultString: string;

  chasse : any = {
    titre : "Démo de chasse",
    texte_intro : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vehicula felis turpis, ac blandit arcu malesuada pharetra. Morbi ut velit a dolor sagittis volutpat pellentesque ut ligula. Phasellus erat est, luctus eget sagittis sit amet, elementum id risus. <br><br>Donec leo ligula, dictum vitae rhoncus laoreet, pellentesque at dolor. Aenean eleifend cursus ultricies. Donec luctus, odio in aliquet volutpat, turpis ex malesuada nulla, eu condimentum turpis massa nec erat.",
    texte_reward : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vehicula felis turpis, ac blandit arcu malesuada pharetra. Morbi ut velit a dolor sagittis volutpat pellentesque ut ligula. Phasellus erat est, luctus eget sagittis sit amet, elementum id risus. <br><br>Donec leo ligula, dictum vitae rhoncus laoreet, pellentesque at dolor. Aenean eleifend cursus ultricies. Donec luctus, odio in aliquet volutpat, turpis ex malesuada nulla, eu condimentum turpis massa nec erat.",
    titreSecond :"Arpentez la galerie à la recherche du totem !",
    duree:"45",
    imgHead: "https://images.unsplash.com/photo-1546927033-1f86960fe403?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1778&q=80", 
    logo: "assets/img/Bitmap.svg",
    imgFoot: "https://images.unsplash.com/photo-1546927033-1f86960fe403?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1778&q=80",
    successIcon: "../../assets/img/final-logo.png",
    reward: "Vous avez trouvé le totem ! Nous espérons que cette chasse vous a permis de découvir la Médiacité différement ! <br/><br/>Vous remportez un <b>bon d’achat de 5€ </b> qui vous a été envoyé par email !",
    finChasse: false,
    title_continue: "Que voulez-vous faire ?",
    text_continue: "Voulez-vous continuer le jeu ou recommencer une partie ?",
    btn_continue: "Je continue",
    btn_remake: "Je recommence une partie",
    text_congrats: "<h2 class=\"txt-success\">Vous voilà à la dernière étape !</h2>\n",
    "text_params": "<p>Ce jeu est édité par la société <a class=\"ludifica\" href=\"https://www.ludifica.be\">Ludifica</a> spécialisée en gamification.</p>\n",
    "title_params": "À propos",
    "title_btn_params": "Envie de recommencer la partie? Cliquez sur le bouton ci-dessous pour réinitialiser le jeu.",
    "button_params": "Recommencer le jeu",
    clues:[
      {
        "title": "Point de départ",
            "type": "info",
            "has_symbol": false,
            "symbole": 523,
            "content": "<p>En quelle année a ouvert ses portes la Média Cité ?</p>\n<p>(==&gt; réponse : 2009)</p>\n",
            "code": false,
            "good_answer": false,
            "reponse_min": "",
            "reponse_max": "",
            "qr_code": false,
            "symbol_grid": {
                "image": false,
                "position": {
                    "lat": "",
                    "lng": "",
                    "address": ""
                }
            },
        "date": "21/10/2009",
        "date_format": "YYYY",
        "unlock_content": "<p>Pour débuter la chasse, rendez-vous devant ce manège. Une fois sur place, cliquez sur le bouton « J’y suis, c’est parti ! ».</p>\n",
        "help": false,
        "rules": "<p>\nAprès avoir scanné le QR code de départ vous devrez répondre à une série de questions.<br />\nLorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vehicula felis turpis, ac blandit arcu malesuada pharetra.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vehicula felis turpis, ac blandit arcu malesuada pharetra.</p>\n",
        "img_start": "https://images.unsplash.com/photo-1531012451721-432c0ae74527?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80",
        "subtitle_start": "Manège du parc",
        "button_start": "J’y suis, c’est parti !",
        "button_rules": "J’ai compris !",
        "button_qr": "",
        "image_trash1": false,
        "response_trash1": false,
        "image_trash2": false,
        "response_trash2": false,
        "image_trash3": false,
        "response_trash3": false,
        "image_trash4": false,
        "response_trash4": false,
        "title_trash1": "",
        "title_trash2": "",
        "title_trash3": "",
        "color_trash1": "",
        "color_trash2": "",
        "color_trash3": "",
        "id": 1,
        "symbol": []
    },
    {
        "title": "QR start",
        "type": "qr",
        "has_symbol": false,
        "symbole": 797,
        "content": "<p>Cherchez et scannez le QR code qui se trouve en face du Guapa pour commencer la partie.</p>\n",
        "code": [
            {
                "value": "abeille"
            },
            {
                "value": "bourdonnement"
            },
            {
                "value": "abeilles"
            }
        ],
        "good_answer": [
            {
                "value": "ETFE"
            }
        ],
        "reponse_min": "",
        "reponse_max": "",
        "qr_code": "0001",
        "symbol_grid": {
            "image": false,
            "position": {
                "lat": "",
                "lng": "",
                "address": ""
            }
        },
        "date": "21/10/2009",
        "date_format": "YYYY",
        "unlock_content": "",
        "help": false,
        "rules": "",
        "img_start": false,
        "subtitle_start": "",
        "button_start": "",
        "button_rules": "",
        "button_qr": "Scanner le QR code",
        "image_trash1": false,
        "response_trash1": false,
        "image_trash2": false,
        "response_trash2": false,
        "image_trash3": false,
        "response_trash3": false,
        "image_trash4": false,
        "response_trash4": false,
        "title_trash1": "",
        "title_trash2": "",
        "title_trash3": "",
        "color_trash1": "",
        "color_trash2": "",
        "color_trash3": "",
        "id": 2,
        "symbol": []
    },
    {
        "title": "QCM matériau toiture",
        "type": "qcm",
        "has_symbol": true,
        "symbole": 631,
        "content": "<p>En quelle année a ètè inauguré ce lieu ?</p>\n",
        "code": [
            {
                "value": "1990"
            },
            {
                "value": "1998"
            },
            {
                "value": "2001"
            },
            {
                "value": "2006"
            }
        ],
        "good_answer": [
            {
                "value": "1990"
            }
        ],
        "reponse_min": "",
        "reponse_max": "",
        "qr_code": "1234",
        "symbol_grid": {
            "image": false,
            "position": {
                "lat": "",
                "lng": "",
                "address": ""
            }
        },
        "date": "21/10/2009",
        "date_format": "YYYY",
        "unlock_content": "<p><span class=\"txt-success\">Bravo ! </span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vehicula felis turpis, ac blandit arcu malesuada pharetra. Morbi ut velit a dolor sagittis volutpat pellentesque ut ligula. Phasellus erat est, luctus eget sagittis sit amet, elementum id risus. <br><br>Donec leo ligula, dictum vitae rhoncus laoreet, pellentesque at dolor. Aenean eleifend cursus ultricies.\n",
        "help": false,
        "rules": "",
        "img_start": false,
        "subtitle_start": "",
        "button_start": "",
        "button_rules": "",
        "button_qr": "",
        "image_trash1": false,
        "response_trash1": false,
        "image_trash2": false,
        "response_trash2": false,
        "image_trash3": false,
        "response_trash3": false,
        "image_trash4": false,
        "response_trash4": false,
        "title_trash1": "",
        "title_trash2": "",
        "title_trash3": "",
        "color_trash1": "",
        "color_trash2": "",
        "color_trash3": "",
        "id": 3,
        "symbol": {
            "image": {
                "s350": "../assets/img/3doc.svg",
                "s160": "../assets/img/3doc.svg"
            },
            "color": "#f2ecce"
        }
    },
    {
        "title": "QR H&M",
        "type": "qr",
        "has_symbol": false,
        "symbole": 631,
        "content": "<p>Rendez-vous maintenant vers les sanitaires après H&#038;M et cherchez après un nouveau QR code à scanner.</p>\n",
        "code": [
            {
                "value": "tuiles"
            },
            {
                "value": "verre"
            },
            {
                "value": "ETFE"
            },
            {
                "value": "roofing"
            }
        ],
        "good_answer": [
            {
                "value": "ETFE"
            }
        ],
        "reponse_min": "77800",
        "reponse_max": "88000",
        "qr_code": "0002",
        "symbol_grid": {
            "image": false,
            "position": {
                "lat": "",
                "lng": "",
                "address": ""
            }
        },
        "date": "21/10/2009",
        "date_format": "YYYY",
        "unlock_content": "",
        "help": false,
        "rules": "",
        "img_start": false,
        "subtitle_start": "",
        "button_start": "",
        "button_rules": "",
        "button_qr": "",
        "image_trash1": false,
        "response_trash1": false,
        "image_trash2": false,
        "response_trash2": false,
        "image_trash3": false,
        "response_trash3": false,
        "image_trash4": false,
        "response_trash4": false,
        "title_trash1": "",
        "title_trash2": "",
        "title_trash3": "",
        "color_trash1": "",
        "color_trash2": "",
        "color_trash3": "",
        "id": 4,
        "symbol": []
    },
    {
        "title": "Panneaux photovoltaïque",
        "type": "fourchette",
        "has_symbol": true,
        "symbole": 621,
        "content": "<p>Combien de visiteurs avons nous en moyenne par jour ?</p><p>Réponse => entre 300 et 500</p>\n",
        "code": [
            {
                "value": "tuiles"
            },
            {
                "value": "verre"
            },
            {
                "value": "ETFE"
            },
            {
                "value": "roofing"
            }
        ],
        "good_answer": [
            {
                "value": "ETFE"
            }
        ],
        "reponse_min": "300",
        "reponse_max": "500",
        "qr_code": "1234",
        "symbol_grid": {
            "image": false,
            "position": {
                "lat": "",
                "lng": "",
                "address": ""
            }
        },
        "date": "21/10/2009",
        "date_format": "YYYY",
        "unlock_content": "<p><span class=\"txt-success\">Bien joué</span>, vous recevez du CO2.</p>\n",
        "help": [
            {
                "label": "Besoin d'un indice?",
                "text": "<p>Regardez sur le panneau vert derrière vous.</p>\n"
            }
        ],
        "rules": "",
        "img_start": false,
        "subtitle_start": "",
        "button_start": "",
        "button_rules": "",
        "button_qr": "",
        "image_trash1": false,
        "response_trash1": false,
        "image_trash2": false,
        "response_trash2": false,
        "image_trash3": false,
        "response_trash3": false,
        "image_trash4": false,
        "response_trash4": false,
        "title_trash1": "",
        "title_trash2": "",
        "title_trash3": "",
        "color_trash1": "",
        "color_trash2": "",
        "color_trash3": "",
        "id": 5,
        "symbol": {
            "image": {
              "s350": "../assets/img/1doc.svg",
              "s160": "../assets/img/1doc.svg"
            },
            "color": "#cad7e5"
        }
    },
    {
        "title": "QR The Voice",
        "type": "qr",
        "has_symbol": false,
        "symbole": 621,
        "content": "<p>Le prochain QR code à scanner est à l&rsquo;extérieur, près des studios de The Voice.</p>\n",
        "code": [
            {
                "value": "eau"
            },
            {
                "value": "pluie"
            },
            {
                "value": "eau de pluie"
            },
            {
                "value": "l'eau"
            },
            {
                "value": "la pluie"
            },
            {
                "value": "l'eau de pluie"
            }
        ],
        "good_answer": false,
        "reponse_min": "77800",
        "reponse_max": "88000",
        "qr_code": "0003",
        "symbol_grid": {
            "image": false,
            "position": {
                "lat": "",
                "lng": "",
                "address": ""
            }
        },
        "date": "02/02/2021",
        "date_format": "DD/MM/YYYY",
        "unlock_content": "",
        "help": false,
        "rules": "",
        "img_start": false,
        "subtitle_start": "",
        "button_start": "",
        "button_rules": "",
        "button_qr": "",
        "image_trash1": false,
        "response_trash1": false,
        "image_trash2": false,
        "response_trash2": false,
        "image_trash3": false,
        "response_trash3": false,
        "image_trash4": false,
        "response_trash4": false,
        "title_trash1": "",
        "title_trash2": "",
        "title_trash3": "",
        "color_trash1": "",
        "color_trash2": "",
        "color_trash3": "",
        "id": 6,
        "symbol": []
    },
    {
        "title": "Récolte eau de pluie ",
        "type": "code",
        "has_symbol": true,
        "symbole": 629,
        "content": "<p>Je tombe du ciel et suis récoltée pour être réutilisée dans l&rsquo;arrosage des plantes, le lavage des objets, l&rsquo;entretien du centre et dans les sanitaires, qui suis-je ?</p>\n",
        "code": [
            {
                "value": "eau"
            },
            {
                "value": "pluie"
            },
            {
                "value": "eau de pluie"
            },
            {
                "value": "l'eau"
            },
            {
                "value": "la pluie"
            },
            {
                "value": "l'eau de pluie"
            },
            {
                "value": "de l'eau"
            },
            {
                "value": "de l'eau de pluie"
            },
            {
                "value": "l eau"
            }
        ],
        "good_answer": false,
        "reponse_min": "77800",
        "reponse_max": "88000",
        "qr_code": "1234",
        "symbol_grid": {
            "image": false,
            "position": {
                "lat": "",
                "lng": "",
                "address": ""
            }
        },
        "date": "02/02/2021",
        "date_format": "DD/MM/YYYY",
        "unlock_content": "<p><span class=\"txt-success\">Bravo ! </span>Vous voici maintenant en possession de l&rsquo;eau de pluie essentielle à la nature.</p>\n",
        "help": false,
        "rules": "",
        "img_start": false,
        "subtitle_start": "",
        "button_start": "",
        "button_rules": "",
        "button_qr": "",
        "image_trash1": false,
        "response_trash1": false,
        "image_trash2": false,
        "response_trash2": false,
        "image_trash3": false,
        "response_trash3": false,
        "image_trash4": false,
        "response_trash4": false,
        "title_trash1": "",
        "title_trash2": "",
        "title_trash3": "",
        "color_trash1": "",
        "color_trash2": "",
        "color_trash3": "",
        "id": 7,
        "symbol": {
            "image": {
              "s350": "../assets/img/6doc.svg",
              "s160": "../assets/img/6doc.svg"
            },
            "color": "#d7ecf4"
        }
    },
    {
        "title": "QR coworking",
        "type": "qr",
        "has_symbol": false,
        "symbole": 629,
        "content": "<p>Retournons à l&rsquo;intérieur et montons maintenant d&rsquo;un étage, plus précisément aux alentours de l&rsquo;espace de coworking.</p>\n",
        "code": [
            {
                "value": "eau"
            },
            {
                "value": "pluie"
            },
            {
                "value": "eau de pluie"
            },
            {
                "value": "l'eau"
            },
            {
                "value": "la pluie"
            },
            {
                "value": "l'eau de pluie"
            }
        ],
        "good_answer": false,
        "reponse_min": "",
        "reponse_max": "",
        "qr_code": "0004",
        "symbol_grid": {
            "image": false,
            "position": {
                "lat": "",
                "lng": "",
                "address": ""
            }
        },
        "date": "02/02/2021",
        "date_format": "DD/MM/YYYY",
        "unlock_content": "",
        "help": false,
        "rules": "",
        "img_start": false,
        "subtitle_start": "",
        "button_start": "",
        "button_rules": "",
        "button_qr": "",
        "image_trash1": false,
        "response_trash1": false,
        "image_trash2": false,
        "response_trash2": false,
        "image_trash3": false,
        "response_trash3": false,
        "image_trash4": false,
        "response_trash4": false,
        "title_trash1": "",
        "title_trash2": "",
        "title_trash3": "",
        "color_trash1": "",
        "color_trash2": "",
        "color_trash3": "",
        "id": 8,
        "symbol": []
    },
    {
      "title": "bourdonnement d'abeilles",
      "type": "audio",
      "has_symbol": true,
      "symbole": 618,
      "content": "<p>Quel est ce bruit ?</p>\n<!--[if lt IE 9]><script>document.createElement('audio');</script><![endif]-->\n<audio class=\"wp-audio-shortcode\" id=\"audio-0-1\" preload=\"none\" style=\"width: 100%;\" controls=\"controls\"><source type=\"audio/wav\" src=\"https://mediacite.noodev.be/wp-content/uploads/2021/04/WINGInsc_Bourdon-1-ID-1000_LS.wav?_=1\" /><a href=\"https://mediacite.noodev.be/wp-content/uploads/2021/04/WINGInsc_Bourdon-1-ID-1000_LS.wav\">https://mediacite.noodev.be/wp-content/uploads/2021/04/WINGInsc_Bourdon-1-ID-1000_LS.wav</a></audio>\n",
      "code": [
          {
              "value": "abeille"
          },
          {
              "value": "bourdonnement"
          },
          {
              "value": "abeilles"
          },
          {
              "value": "une abeille"
          },
          {
              "value": "des abeilles"
          },
          {
              "value": "des bourdonnements"
          }
      ],
      "good_answer": false,
      "reponse_min": "",
      "reponse_max": "",
      "qr_code": "1234",
      "symbol_grid": {
          "image": false,
          "position": {
              "lat": "",
              "lng": "",
              "address": ""
          }
      },
      "date": null,
      "date_format": "DD/MM/YYYY",
      "unlock_content": "<p><span class=\"txt-success\">Bravo!</span></p>\n<p>Voici un nouvel élément pour votre sac à dos.</p>\n",
      "help": [
          {
              "label": "Besoin d'un indice?",
              "text": "<p>Il s’agit d’un insecte qui produit du miel.</p>\n"
          }
      ],
      "rules": "",
      "img_start": false,
      "subtitle_start": "",
      "button_start": "",
      "button_rules": "",
      "button_qr": "",
      "image_trash1": false,
      "response_trash1": false,
      "image_trash2": false,
      "response_trash2": false,
      "image_trash3": false,
      "response_trash3": false,
      "image_trash4": false,
      "response_trash4": false,
      "title_trash1": "",
      "title_trash2": "",
      "title_trash3": "",
      "color_trash1": "",
      "color_trash2": "",
      "color_trash3": "",
      "id": 9,
      "symbol": {
          "image": {
            "s350": "../assets/img/2doc.svg",
            "s160": "../assets/img/2doc.svg"
          },
          "color": "#f4dfc1"
      },
  },
  {
    "title": "Jeu poubelle",
    type: "drag",
    "has_symbol": true,
    "symbole": 797,
    "content": "<p>Triez les déchets dans la bonne poubelle.</p>\n",
    "code": false,
    "good_answer": false,
    "reponse_min": "",
    "reponse_max": "",
    "qr_code": "1234",
    "symbol_grid": {
        "image": false,
        "position": {
            "lat": "",
            "lng": "",
            "address": ""
        }
    },
    "date": null,
    "date_format": "DD/MM/YYYY",
    "unlock_content": "<p><span class=\"txt-success\">Bien trié</span>, vous ne pouvez dorénavant plus vous tromper. Le tri est important partout. À Médiacité, il s&rsquo;agit d&rsquo;une priorité aussi bien pour les clients, les commerçants et les fournisseurs.<br />\nMiam, une pomme de gagnée !</p>\n",
    "help": false,
    "rules": "",
    "img_start": false,
    "subtitle_start": "",
    "button_start": "",
    "button_rules": "",
    "button_qr": "",
    "image_trash1": "https://mediacite.noodev.be/wp-content/uploads/2021/04/poubelle1.svg",
    "response_trash1": "autre",
    "image_trash2": "https://mediacite.noodev.be/wp-content/uploads/2021/04/can.svg",
    "response_trash2": "pmc",
    "image_trash3": "https://mediacite.noodev.be/wp-content/uploads/2021/04/paper-bin.svg",
    "response_trash3": "papier",
    "image_trash4": "https://mediacite.noodev.be/wp-content/uploads/2021/04/mouchoir.png",
    "response_trash4": "autre",
    "title_trash1": "PMC",
    "title_trash2": "Papiers",
    "title_trash3": "autres",
    "color_trash1": "#0455bf",
    "color_trash2": "#f2e422",
    "color_trash3": "#30312b",
    "id": 11,
    "symbol": {
        "image": {
          "s350": "../assets/img/4doc.svg",
          "s160": "../assets/img/4doc.svg"
        },
        "color": ""
    }
},
{
  "title": "Puzzle",
  "type": "puzzle",
  "has_symbol": true,
  "symbole": 800,
  "content": "<p>Remettez ce puzzle dans l&rsquo;ordre.</p>\n",
  "code": false,
  "good_answer": false,
  "reponse_min": "12",
  "reponse_max": "13",
  "qr_code": "1234",
  "symbol_grid": {
      "image": false,
      "position": {
          "lat": "",
          "lng": "",
          "address": ""
      }
  },
  "date": null,
  "date_format": "DD/MM/YYYY",
  "unlock_content": "<p><span class=\"txt-success\">Bien joué</span>, vous avez recomposé l&rsquo;ancienne friche industrielle sur laquelle Médiacité a été construit. Au total, une année entière a été nécessaire pour dépolluer le sol.<br />\nDès le début, le complexe a été pensé de manière durable. Plusieurs certifications écologiques lui ont d&rsquo;ailleurs été attribuées.<br />\nPour ce nouvel accomplissement, voici des racines.</p>\n",
  "help": false,
  "rules": "",
  "img_start": false,
  "subtitle_start": "",
  "button_start": "",
  "button_rules": "",
  "button_qr": "",
  "image_trash1": false,
  "response_trash1": false,
  "image_trash2": false,
  "response_trash2": false,
  "image_trash3": false,
  "response_trash3": false,
  "image_trash4": false,
  "response_trash4": false,
  "title_trash1": "",
  "title_trash2": "",
  "title_trash3": "",
  "color_trash1": "",
  "color_trash2": "",
  "color_trash3": "",
  "id": 15,
  "symbol": {
      "image": {
        "s350": "../assets/img/5doc.svg",
        "s160": "../assets/img/5doc.svg"
      },
      "color": "#d6c2b6"
  }
},
{
  "title": "QR coworking",
  "type": "qr",
  "has_symbol": false,
  "symbole": 629,
  "content": "<p>Retournons à l&rsquo;intérieur et montons maintenant d&rsquo;un étage, plus précisément aux alentours de l&rsquo;espace de coworking.</p>\n",
  "code": [
      {
          "value": "eau"
      },
      {
          "value": "pluie"
      },
      {
          "value": "eau de pluie"
      },
      {
          "value": "l'eau"
      },
      {
          "value": "la pluie"
      },
      {
          "value": "l'eau de pluie"
      }
  ],
  "good_answer": false,
  "reponse_min": "",
  "reponse_max": "",
  "qr_code": "0004",
  "symbol_grid": {
      "image": false,
      "position": {
          "lat": "",
          "lng": "",
          "address": ""
      }
  },
  "date": "02/02/2021",
  "date_format": "DD/MM/YYYY",
  "unlock_content": "",
  "help": false,
  "rules": "",
  "img_start": false,
  "subtitle_start": "",
  "button_start": "",
  "button_rules": "",
  "button_qr": "",
  "image_trash1": false,
  "response_trash1": false,
  "image_trash2": false,
  "response_trash2": false,
  "image_trash3": false,
  "response_trash3": false,
  "image_trash4": false,
  "response_trash4": false,
  "title_trash1": "",
  "title_trash2": "",
  "title_trash3": "",
  "color_trash1": "",
  "color_trash2": "",
  "color_trash3": "",
  "id": 8,
  "symbol": []
}
      ],
  };
  utilisateur = {
    nom : null,
    prenom : null,
    email: null,
    endChasse: false,
    nbEtape : null,
    condition: false,
    position: 0,
    unlockEtape: 0,
    sac:[],
    sacColor:[],
    showBag: false,
    reponseQuestionDate: [
      null,
      null,
      null
    ],
    reponseQuestion: null,
    nbParticipant: null,
  };
  
  constructor(private _http: HttpClient) {
    
   }
  
  widthBar(){
    let newWidth = null;
    newWidth = 100/(Object.keys(this.chasse.clues).length - 1) * this.utilisateur.position;
    console.log(newWidth);
    return newWidth;
    
    
  }
  // onCodeResult(resultString: string,position: number) {
    
  //   if(resultString == this.chasse.etape[position].urlQr){
  //     this.utilisateur.position++;
  //     this.utilisateur.unlockEtape++;
  //     this.qrResultString = "Vous avez scanné le bon code 🎉";
  //   }else{
  //     this.qrResultString = "Pas le bon code :)";
  //   }
  // }
  thePositionRight(){
    if(this.utilisateur.position < this.utilisateur.unlockEtape){
      return false;
    }else{
      return true;
    }
  }

  thePositionLeft(){
    if(this.utilisateur.position == 0 || this.utilisateur.position < 0 ){
      return true;
    }else{
      return false;
    }
  }

  nbEtapes(){
    let nb =Object.keys(this.chasse.clues).length - 1;
    return nb;
  }

  // nextStep(){
  //   if(this.utilisateur.position == this.utilisateur.unlockEtape){
  //     if(this.chasse.etape[this.utilisateur.position].objet){
  //       this.utilisateur.sac.push(this.chasse.etape[this.utilisateur.position].objet.image);
  //       console.log('add image');
  //     }
  //     this.utilisateur.position++;
  //     this.utilisateur.unlockEtape++;
  //     scroll(0,0);
  //   }else{
  //     this.utilisateur.position++;
  //     scroll(0,0);
  //   }
  // }

  previousPosition(){
    if(this.utilisateur.position <= 0){
      return
    }else{
      this.utilisateur.position = this.utilisateur.position - 1;
      scroll(0,0);
    }
    
  }

  nextPosition(){
    if(this.utilisateur.position < this.utilisateur.unlockEtape){
      this.utilisateur.position++;
      scroll(0,0);
    }
  }

  // unlock(){
  //   if(this.utilisateur.position == this.utilisateur.unlockEtape){
  //     if(this.chasse.etape[this.utilisateur.position].objet){
  //       this.utilisateur.sac.push(this.chasse.etape[this.utilisateur.position].objet.image);
  //       console.log('add image');
  //     }
  //     this.utilisateur.unlockEtape++;
  //   }
  // }

  // checkQuestion(){
  //   if(this.utilisateur.reponseQuestion[0].toLowerCase() == this.chasse.etape[this.utilisateur.position].reponse.toLowerCase()){
  //     this.nextStep()
  //   }else{
  //     if(this.chasse.etape[this.utilisateur.position].essaisUser <= 0){
  //       //si le disabled est desactiver par le navigateur
  //       return;
  //     }else{
  //       this.chasse.etape[this.utilisateur.position].essaisUser--;
  //       if(this.chasse.etape[this.utilisateur.position].essaisUser == 0){
  //         console.log("go timer");
  //       }
  //     }
  //   }
  // }

  addObject(){
    this.utilisateur.sac.push("../../assets/img/objet-2.svg");
    this.utilisateur.sac.push("../../assets/img/objet-3.svg");
    this.utilisateur.sac.push("../../assets/img/objet-4.svg");
 }

 endChasse(){
  this.chasse.finChasse = true;
  scroll(0,0);
 }

//  switchSuccess(){
//   this.chasse.etape[this.utilisateur.position].success = true;
//   scroll(0,0);
//  }
}

<div *ngIf="myHunt.clues[idclues].type == 'qcm'" class="chasse-qcm">
    <p *ngIf="HuntService.utilisateur.position == idclues" class="txt-secondary" [innerHTML]="myHunt.clues[idclues].content"></p>
    <div *ngIf="HuntService.utilisateur.position == idclues" class="qcm-list">
        <button *ngIf="HuntService.utilisateur.position == idclues" class="button-totemus" [disabled]="qcm[0]==true?true:false"  [ngClass]="qcmGood[0]==true?'goodQcm':''" (click)="checkQCM(myHunt.clues[idclues].good_answer[0].value,myHunt.clues[idclues].code[0].value,0)" >{{myHunt.clues[idclues].code[0].value}}</button>
        <button *ngIf="HuntService.utilisateur.position == idclues" class="button-totemus"  [disabled]="qcm[1]==true?true:false" [ngClass]="qcmGood[1]==true?'goodQcm':''" (click)="checkQCM(myHunt.clues[idclues].good_answer[0].value,myHunt.clues[idclues].code[1].value,1)" >{{myHunt.clues[idclues].code[1].value}}</button>
        <button *ngIf="HuntService.utilisateur.position == idclues" class="button-totemus"  [disabled]="qcm[2]==true?true:false" [ngClass]="qcmGood[2]==true?'goodQcm':''" (click)="checkQCM(myHunt.clues[idclues].good_answer[0].value,myHunt.clues[idclues].code[2].value,2)" >{{myHunt.clues[idclues].code[2].value}}</button>
        <button *ngIf="HuntService.utilisateur.position == idclues" class="button-totemus"  [disabled]="qcm[3]==true?true:false" [ngClass]="qcmGood[3]==true?'goodQcm':''" (click)="checkQCM(myHunt.clues[idclues].good_answer[0].value,myHunt.clues[idclues].code[3].value,3)" >{{myHunt.clues[idclues].code[3].value}}</button>

        <p class="indice" *ngIf="myHunt.clues[idclues].help[0]" [innerHTML]="myHunt.clues[idclues].help[0].label" (click)="showIndice()"></p>
    </div>
    <p  *ngIf="HuntService.utilisateur.position > idclues" class="txt-secondary"  [innerHTML]="myHunt.clues[idclues].unlock_content"></p>
    
    <div class="symbol-box" *ngIf="myHunt.clues[idclues].has_symbol == true && HuntService.utilisateur.position > idclues" [style.backgroundColor]="myHunt.clues[idclues].symbol.color">
        <img [src]="myHunt.clues[idclues].symbol.image.s160" alt="symbole débloqué">
    </div>
    <button *ngIf="HuntService.utilisateur.position > idclues" class="button-totemus" (click)="showNextStep()">Prochaine étape</button>
</div>